import React, { Fragment } from 'react'
import {Tooltip} from "@material-tailwind/react";
import {
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { CheckIcon, ChevronDownIcon, MinusIcon } from '@heroicons/react/20/solid'

const tiers = [
  {
    name: 'Flat',
    displayName: 'Flat Storage',
    href: 'https://secure.vault56.com/signup',
    priceMonthly: 8,
    description: 'Low cost storage for secondary backups.'
  },
  {
    name: 'Extended',
    displayName: 'Extended Security Storage',
    href: 'https://secure.vault56.com/signup',
    priceMonthly: 12,
    description: 'Secure redundancy storage for more sensitive data.',
  },
  {
    name: 'Multi',
    displayName: 'Multi-Location Storage (coming soon)',
    href: 'https://secure.vault56.com/signup',
    priceMonthly: 16,
    description: 'Versatile storage, with support for multiple storage location redundancy.',
  },
]
const sections = [
  {
    name: 'Savings on reserved storage',
    features: [
      {
        name: '1TB paid annually in advance',
        tiers: { Flat: '5% discount', Extended: '5% discount', Multi: '5% discount' },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'If you pay for 1TB annually in advance, we give you 5% discount.',
      },
      {
        name: 'Reserved Capacity (min 20TB)',
        tiers: { Flat: '10% discount', Extended: '10% discount', Multi: '10% discount' },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'Reserved capacity, paid annually in advance, minimum 20TB with 10TB increments, no upper limit.',
      },
    ],
  },
  {
    name: 'API Support',
    features: [
      {
        name: 'S3 Protocol',
        tiers: { Flat: true, Extended: true, Multi: true },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'S3 is a simple storage protocol that allows you to use any S3 compatible client to access your data.',
      },
      {
        name: 'HTTP Post API',
        tiers: { Flat: false, Extended: false, Multi: true },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'HTTP Post API allows you to upload files directly to your storage location using standard HTTP protocol.',
      },
      {
        name: 'vault56 REST API',
        tiers: { Flat: false, Extended: true, Multi: true },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'vault56 REST API allows you to manage your storage using our REST API.',
      },
    ],
  },
  {
    name: 'Security Features',
    features: [
      {
        name: 'Ransomware Protection',
        tiers: { Flat: true, Extended: true, Multi: true },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'Ransomware protection is a feature that stops your data from being altered and allows you to recover your data in the event of a ransomware attack.',
      },
      {
        name: 'Multi Storage Replication',
        tiers: { Multi: '2 storage locations' },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'Multi storage replication allows you to store copies your data in multiple locations.',
      },
      {
        name: 'Erasure Coding',
        tiers: { Flat: false, Extended: true, Multi: true },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'Erasure coding allows you to store your data in multiple locations, and recover from any location.',
      },
      {
        name: 'File Versioning',
        tiers: { Flat: false, Extended: true, Multi: false },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'File versioning allows you to keep multiple versions of a file, and restore any version at any time.',
      },
      {
        name: 'Server Data Encryption',
        tiers: { Flat: true, Extended: true, Multi: true },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'Data encryption on server level.',
      },
    ],
  },
  {
    name: 'Additional Features',
    features: [
      {
        name: 'Native File System',
        tiers: { Flat: 'Linux/Mac', Extended: 'Linux/Mac/Win', Multi: 'Linux/Mac' },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'Native file system allows you to access your data using the native file system on your operating system.',
      },
      {
        name: 'Automatic Expiration',
        tiers: { Flat: false, Extended: false, Multi: true },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'Automatic expiration allows you to set an expiration date for your files, and have them automatically deleted.',
      },
    ],
  },
  {
    name: 'Additional Costs',
    features: [
      {
        name: 'Data Download',
        tiers: { Flat: 'Twice your stored data', Extended: 'Twice your stored data', Multi: 'Twice your stored data' },
        href: 'https://secure.vault56.com/signup',
        tooltip: 'You can download twice your stored amount of data each month for free. Additional downloads will be charged at €0.01/GB.',
      },
    ],
  },
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function V56TablePricing() {
  return (
    <div className="bg-white" id="pricing">
      <div className="sm:flex sm:flex-col sm:align-center">
        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          Honest, simple pricing. No hidden fees.
        </p>
      </div>
      {/* Comparison table */}
      <div className="mx-auto max-w-2xl bg-white py-16 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* xs to lg */}
        <div className="space-y-24 lg:hidden">
          {tiers.map((tier) => (
            <section key={tier.displayName}>
              <div className="mb-8 px-4">
                <h2 className="text-lg font-medium leading-6 text-gray-900">{tier.displayName}</h2>
                <p className="mt-4">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">€{tier.priceMonthly}</span>
                  <span className="text-base font-medium text-gray-500">/mo/TB</span>
                </p>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                {tier.name.toLowerCase() !== 'multi'?
                <a
                  href={tier.href}
                  className="mt-6 block w-full rounded-md border border-transparent bg-[#5f2881] py-2 text-center text-sm font-semibold text-white shadow hover:to-pink-600"
                >
                  Try {tier.name}
                </a>:''}
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="border-t border-gray-200 bg-gray-50 py-3 px-4 text-left text-sm font-medium text-gray-900">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th className="py-5 px-4 text-left text-sm font-normal text-gray-500" scope="row">
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-right text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div className="border-t border-gray-200 px-4 pt-5">
                {tier.name.toLowerCase() !== 'multi'?
                <a
                  href="https://secure.vault56.com/signup"
                  className="block w-full rounded-md border border-transparent bg-[#5f2881] py-2 text-center text-sm font-semibold text-white shadow hover:to-pink-600"
                >
                  Try {tier.name}
                </a>:''}
              </div>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="h-px w-full table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th className="pb-4 pl-6 pr-6 text-left text-sm font-medium text-gray-900" scope="col">
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier.displayName}
                    className="w-1/4 px-6 pb-4 text-left text-lg font-medium leading-6 text-gray-900"
                    scope="col"
                  >
                    {tier.displayName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 border-t border-gray-200">
              <tr>
                <th className="py-8 pl-6 pr-6 text-left align-top text-sm font-medium text-gray-900" scope="row">
                  Pricing
                </th>
                {tiers.map((tier) => (

                  <td key={tier.displayName} className="h-full py-8 px-6 align-top">
                    <div className="flex h-full flex-col justify-between">
                      <div>
                        <p>
                          <span className="text-4xl font-bold tracking-tight text-gray-900">€{tier.priceMonthly}</span>
                          <span className="text-base font-medium text-gray-500">/mo/TB</span>
                        </p>
                        <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th className="py-5 pl-6 pr-6 text-left text-sm font-normal text-gray-500" scope="row">
                        <div className="flex">
                        {feature.name + ' '}
                          <Tooltip
                              content={feature.tooltip}
                              animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                              }}
                          >
                            <InformationCircleIcon className="h-5 w-5 text-[#6f4488]" />
                          </Tooltip>
                        </div>
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.name} className="py-5 px-6">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="px-6 pt-5">
                    {tier.name.toLowerCase() !== 'multi'?
                    <a
                      href="https://secure.vault56.com/signup"
                      className="block w-full rounded-md border border-transparent bg-[#5f2881] py-2 text-center text-sm font-semibold text-white shadow hover:to-pink-600"
                    >
                      Try {tier.name}
                    </a>:''}
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

    </div>
  )
}
