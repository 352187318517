import {
    CloudArrowDownIcon,
    ShieldCheckIcon,
    MapPinIcon,
    ClockIcon,
    ArrowsUpDownIcon,
    TableCellsIcon
} from "@heroicons/react/24/outline";

const v56features = [
    {
        name: 'No egress fees',
        description: 'When you use our reserved capacity packages from 60TB up, there are no egress fees. sleep well knowing that unlike most of the competition, we won\'t charge you to get your data back.',
        icon: CloudArrowDownIcon,
    },
    {
        name: 'Multiple locations',
        description: 'Are you looking at spreading your data on multiple locations to secure it even more? We have your back. Our storage solution has multiple location support built in.',
        icon: MapPinIcon,
    },
    {
        name: 'vault56 Time Travel',
        description: 'Are you looking for a specific version of your document? The one from the beginning of last month? No worries, just use our time travel functionality. Never worry again.',
        icon: ClockIcon,
    },
    {
        name: 'Ransomware protection',
        description: 'Secure your data in our cloud storage, if you get hit by ransomware, we got you covered and your data is secure.',
        icon: ShieldCheckIcon,
    },
    {
        name: 'S3 compatibility',
        description: 'Use your existing backup software, such as Veeam, point it to our cloud storage and you are already saving big. It\'s that simple.',
        icon: ArrowsUpDownIcon,
    },
    {
        name: 'File versioning',
        description: 'Every time you change a file, we can save the old version. Just retrieve the old version from vault56 cloud storage.',
        icon: TableCellsIcon,
    },
]

export default function V56Features() {
    return (
        <div className="relative py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-base font-semibold uppercase tracking-wider text-[#e6581c]">EASY, SECURE STORAGE</h2>
                <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    We got you covered
                </p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-[#5f2881]">
                    For a fraction of the cost, get full security, full functionality with no downsides. So you can sleep well.
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {v56features.map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-[#f1b960] p-3 shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                                        <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
