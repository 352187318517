import { Disclosure } from '@headlessui/react'
import {legalPage_} from "../App";
import {useRecoilState} from "recoil";

const navigation = [
    { name: 'Home', href: '#home', page: 0 },
    { name: 'About', href: '#about', page: 1 },
    { name: 'Pricing', href: '#pricing', page: 2 },
    { name: 'Documentation', href: 'https://docs.vault56.com', page: 3 },
    { name: 'Contact', href: '#contact', page: 4 },
]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function V56Header() {

    const [legalPage, setLegalPage] = useRecoilState(legalPage_);

    if (legalPage) {
        navigation[0].href = 'https://vault56.com';
        navigation[1].href = 'https://vault56.com';
        navigation[2].href = 'https://vault56.com';
        navigation[4].href = 'https://vault56.com';
    } else {
        navigation[0].href = '#home';
        navigation[1].href = '#about';
        navigation[2].href = '#pricing';
        navigation[4].href = '#contact';
    }

    return (
        <Disclosure as="nav" className="bg-[#5f2881] sticky top-0 z-30">
            {({open}) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                        </svg>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-start sm:items-stretch">
                                <div className="flex-shrink-0 flex">
                                    <img
                                        className="h-5 lg:h-8 w-auto ml-11 lg:ml-0"
                                        src={require('../assets/img/56_logo_inv.png')}
                                        alt="vault56"
                                    />
                                </div>
                                <div className="hidden lg:block lg:ml-10">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.page===5 ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'px-3 py-2 rounded-md text-sm font-medium'
                                                )}
                                                aria-current={item.page===5 ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 border-0 border-dotted">
                                <div className="">
                                    <a
                                        href="https://secure.vault56.com"
                                        className='text-gray-300 hover:text-[#e6581c] rounded-md text-sm font-medium'
                                        aria-current={undefined}
                                    >
                                    <span className="text-gray-300 hover:text-[#e6581c]">
                                    <li className="flex space-x-3 pr-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
</svg>
                                        <span>Login</span>
                                    </li>
                                    </span>
                                    </a>
                                </div>
                                <div className="border-solid border-2 border-[#e9e9e9] rounded-full">
                                    <a
                                        href="https://secure.vault56.com/signup"
                                        className='text-gray-300 p-4 rounded-md text-sm font-medium hover:text-[#e6581c]'
                                        aria-current={undefined}
                                    >
                                        Start Free Trial
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Mobile menu */}
                    <Disclosure.Panel className="lg:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    className={classNames(
                                        item.page===5 ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block px-3 py-2 rounded-md text-base font-medium'
                                    )}
                                    aria-current={item.page===5 ? 'page' : undefined}
                                >
                                    <a href={item.href}>{item.name}</a>
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )

}
