import React from "react";
import { CheckIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/solid';
import {CheckIcon as CheckIconOutline} from '@heroicons/react/outline'
const features = [
    {
        name: 'Backup',
        description: 'Backup your organisations data reliably from your NAS, workstations, servers and existing backup software, such as Veeam.',
    },
    {
        name: 'Extremely cost effective',
        description: 'No hidden fees of any kind, total transparency, very low prices, no delete penalties.',
    },
    {
        name: 'Easy to use',
        description: 'Super simple to use, just point your existing backup software to our cloud storage, and you are ready to go.',
    },
    {
        name: 'Safe & Reliable',
        description: 'Sleep well knowing that your data is safe with vault56 Cloud Storage. We have exceptionally high security standards.',
    },
]

export class V56Pricing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeView: '1y',
            pricePerOneTB: 8, // Price for 1TB per month
            currency: 'eur',
            USDMultiplier: 1.1, // Multiplier for USD
            activeCss: 'relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-6',
            inactiveCss: 'ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-6',
            tiers: [
                {
                    topic: 'Pay as you go, 1TB included.',
                    name: '',
                    href: 'https://secure.vault56.com/signup',
                    numberOfTerabytes: 1,
                    reservedCapacity: false,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 5,
                    discountPercentage3y: 5,
                    discountPercentage5y: 5,
                    description: 'Only pay for what you use, per TB per month',
                    includedFeatures: [
                        'S3 compatible protocol',
                        'Download €0.01/GB',
                        'Ransomware protection',
                        'File versioning'
                    ],
                },
                {
                    topic: '30TB Reserved',
                    name: '30TB',
                    href: 'https://secure.vault56.com/signup',
                    numberOfTerabytes: 30,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 5,
                    discountPercentage3y: 5,
                    discountPercentage5y: 5,
                    description: 'Space for your company backups, with low fees',
                    includedFeatures: [
                        'S3 compatible protocol',
                        'Download €0.001/GB',
                        'Ransomware protection',
                        'File versioning'
                    ],
                },
                {
                    topic: '60TB Reserved',
                    name: '60TB',
                    href: 'https://secure.vault56.com/signup',
                    numberOfTerabytes: 60,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 5,
                    discountPercentage3y: 5,
                    discountPercentage5y: 5,
                    description: 'Space for your company backups with no additional fees.',
                    includedFeatures: [
                        'S3 compatible protocol',
                        'No download fees',
                        'Ransomware protection',
                        'File versioning'
                    ],
                },
                {
                    topic: '120TB Reserved',
                    name: '120TB',
                    href: 'https://secure.vault56.com/signup',
                    numberOfTerabytes: 120,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 7.5,
                    discountPercentage3y: 7.5,
                    discountPercentage5y: 7.5,
                    description: 'Space for your company backups without additional fees, with additional features.',
                    includedFeatures: [
                        'S3 compatible protocol',
                        'No download fees',
                        'Ransomware protection',
                        'File versioning',
                        'Select storage location'
                    ],
                },
                {
                    topic: '240TB Reserved',
                    name: '240TB',
                    href: 'https://secure.vault56.com/signup',
                    numberOfTerabytes: 240,
                    reservedCapacity: true,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 10,
                    discountPercentage3y: 10,
                    discountPercentage5y: 10,
                    description: 'Space for your company backups without additional fees, with additional features.',
                    includedFeatures: [
                        'S3 compatible protocol',
                        'No download fees',
                        'Ransomware protection',
                        'File versioning',
                        'Selectable storage location',
                        'Multiple storage location capability'
                    ],
                },
                {
                    topic: 'I want more storage',
                    name: 'Custom',
                    href: 'mailto:sales@vault56.com',
                    numberOfTerabytes: 0,
                    reservedCapacity: false,
                    discountPercentageMonthly: 0,
                    discountPercentage1y: 0,
                    discountPercentage3y: 0,
                    discountPercentage5y: 0,
                    description: 'Compose your own custom storage package',
                    includedFeatures: [
                        'You choose'
                    ],
                }
                ]

        };

        // Check if we have local storage saved
        /*const jsonGet = localStorage.getItem("settings");
        const settings = JSON.parse(jsonGet);
        if (settings) {
            // We have settings. Set state
            this.state = settings;
        } else {
            // There are no locally saved settings. Save them
            const json = JSON.stringify(this.state);
            localStorage.setItem("settings", json);
        }*/
    }

    getMultiplier() {
        if (this.state.currency === 'eur') {
            return 1;
        } else {
            return this.state.USDMultiplier;
        }
    }

    getMonthlyPrice(tier, applyDiscount=true) {
        if (applyDiscount) {
            return (((this.state.pricePerOneTB * tier.numberOfTerabytes)*(1-(this.getDiscountPercent(tier)/100)))*this.getMultiplier()).toFixed();
        }
        return ((this.state.pricePerOneTB * tier.numberOfTerabytes)*this.getMultiplier()).toFixed();
    }

    getMonthlySavings(tier, asPercent=false) {
        if (asPercent) {
            return this.getMonthlyPrice(tier, false)-this.getMonthlyPrice(tier);
        }
        return this.getMonthlyPrice(tier, false)-this.getMonthlyPrice(tier);
    }

    getYearlySavings(tier, asPercent=false) {
        if (asPercent) {
            return this.getYearlyPrice(tier, false)-this.getYearlyPrice(tier);
        }
        return this.getYearlyPrice(tier, false)-this.getYearlyPrice(tier);
    }

    getMonthsFromPeriod() {
        switch (this.state.activeView) {
            case 'monthly':
                return 1;
            case '1y':
                return 12;
            case '3y':
                return (12*3);
            case '5y':
                return (12*5);
            default:
                return 1;
        }
    }

    getYearsText() {
        switch (this.state.activeView) {
            case 'monthly':
                return 'monthly';
            case '1y':
                return 'yearly';
            case '3y':
                return '3 years';
            case '5y':
                return '5 years';
            default:
                return 'monthly';
        }
    }

    getDiscountPercent(tier) {
        switch (this.state.activeView) {
            case 'monthly':
                return tier.discountPercentageMonthly;
            case '1y':
                return tier.discountPercentage1y;
            case '3y':
                return tier.discountPercentage3y;
            case '5y':
                return tier.discountPercentage5y;
            default:
                return tier.discountPercentageMonthly;
        }
    }

    getYearlyPrice(tier, applyDiscount=true) {
        if (applyDiscount) {
            return (((this.getMonthlyPrice(tier, false)*this.getMonthsFromPeriod())*(1-(this.getDiscountPercent(tier)/100)))*this.getMultiplier()).toFixed();
        }
        return ((this.getMonthlyPrice(tier, false)*this.getMonthsFromPeriod())*this.getMultiplier()).toFixed();
    }

    getCurrencySymbol() {
        return (this.state.currency === 'eur' ? '€' : '$');
    }

    isYearly() {
        return (this.state.activeView !== 'monthly');
    }

    getPrice(tier) {
        if (this.isYearly()) {
            return this.getYearlyPrice(tier).toString();
        } else {
            return this.getMonthlyPrice(tier).toString();
        }
    }

    getSavingsText(tier) {
        if (this.isYearly()) {
            if (this.getYearlySavings(tier) > 0.0) {
                return 'Save ' + this.getCurrencySymbol() + this.getYearlySavings(tier);
            }
        } else {
            if (this.getMonthlySavings(tier) > 0.0) {
                return 'Save ' + this.getCurrencySymbol() + this.getMonthlySavings(tier);
            }
        }

        return '';
    }

    getHidden(tier) {
        switch (this.state.activeView) {
            case 'monthly':
                return tier.reservedCapacity;
            case '3y':
                return !tier.reservedCapacity;
            default:
                return false;
        }
    }

    render() {
        return (
            <>
            <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8" id="pricing">
                <div className="sm:flex sm:flex-col sm:align-center">
                    <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
                    <p className="mt-5 text-xl text-gray-500 sm:text-center">
                        Honest, simple pricing. No hidden fees.
                    </p>
                    <div className="relative self-center mt-8 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
                        <button
                            onClick={() => {
                                this.setState({activeView: 'monthly'});
                            }}
                            type="button"
                            className={(this.state.activeView === 'monthly' ? this.state.activeCss : this.state.inactiveCss)}
                        >
                            Pay as you go
                        </button>
                        <button
                            onClick={() => {
                                this.setState({activeView: '1y'});
                            }}
                            type="button"
                            className={(this.state.activeView === '1y' ? this.state.activeCss : this.state.inactiveCss)}
                        >
                            1 year
                        </button>
                        <button
                            onClick={() => {
                                this.setState({activeView: '3y'});
                            }}
                            type="button"
                            className={(this.state.activeView === '3y' ? this.state.activeCss : this.state.inactiveCss) + ' disabled:cursor-not-allowed disabled:line-through disabled:text-red-600'}
                            title={(this.state.currency === 'usd')?'USD is not available on 3 year reserved plans':''}
                            disabled={(this.state.currency === 'usd')}
                        >
                            3 years
                        </button>
                        <div className="pl-4">
                            <div className="mt-1.5 relative pb-1 pr-1 ml-2">
                                <select
                                    onChange={(e) => {
                                        if (e.target.value === 'Europe (EUR)') {
                                            this.setState({currency: 'eur'});
                                        } else {
                                            this.setState({currency: 'usd'});
                                        }
                                    }}
                                    id="currency"
                                    name="currency"
                                    className="appearance-none block w-full bg-none bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 text-base text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                                    defaultValue="Europe (EUR)"
                                    disabled={(this.state.activeView === '3y' && this.state.currency === 'eur')}
                                    title={(this.state.activeView === '3y' && this.state.currency === 'eur')?'USD is not available on 3 year reserved plans':''}
                                    >
                                    <option>Europe (EUR)</option>
                                    <option>United States (USD)</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                                    <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
                    {this.state.tiers.map((tier) => (
                        <div key={tier.name}
                             className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 disabled:opacity-10"
                             style={{opacity: (this.getHidden(tier)?0.3:1)}}
                             >
                            <div className="p-6">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.topic}</h2>
                                <p className="mt-4 text-sm text-gray-500 h-10">{tier.description}</p>
                                <p className="mt-8">
                                    <span
                                        className="text-4xl font-extrabold text-gray-900">{
                                        tier.name.toLowerCase() === 'custom'?'':this.getHidden(tier)?'':this.getCurrencySymbol()}{tier.name.toLowerCase() === 'custom'?'':this.getHidden(tier)?'':this.getPrice(tier)}</span>{' '}
                                    <span className="text-base font-medium text-gray-500">{tier.name.toLowerCase() === 'custom'?'Custom price':this.getHidden(tier)?'Not available':'/' + this.getYearsText()}</span>
                                </p>
                                <p className="mt-4 text-sm text-gray-500 h-2">{this.getHidden(tier)?'':this.getSavingsText(tier)}</p>
                                <a
                                    href={(this.getHidden(tier)?'#none':tier.href)}
                                    className={'mt-8 block w-full bg-[#5f2881] border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-[#6f4488]' + (this.getHidden(tier)?' cursor-not-allowed':'')}
                                    title={(this.getHidden(tier)?'Not available in this configuration':'')}
                                >
                                    {tier.name.toLowerCase() === 'custom'?'Contact sales':'Buy ' + tier.name}
                                </a>
                            </div>
                            <div className="pt-6 pb-8 px-6">
                                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's
                                    included</h3>
                                <ul className="mt-6 space-y-4">
                                    {tier.includedFeatures.map((feature) => (
                                        <li key={feature} className="flex space-x-3">
                                            <CheckIcon className="flex-shrink-0 h-5 w-5 text-[#d21e48]"
                                                       aria-hidden="true"/>
                                            <span className="text-sm text-gray-500">{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Feature list */}
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-base text-[#e6581c] font-semibold tracking-wide uppercase">If you're not convinced yet...</h2>
                </div>
                <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative">
                            <dt>
                                <CheckIconOutline className="absolute h-6 w-6 text-[#d21e48]" aria-hidden="true" />
                                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                            </dt>
                            <dd className="mt-2 ml-9 flex text-base text-gray-500 lg:py-0 lg:pb-4">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
            </>
        );
    }

}
