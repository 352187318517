/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'

export default function V56CookiePolicy() {
    const [show, setShow] = useState(true)

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 mt-10 pointer-events-none sm:p-6"
            >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
                            <div className="w-0 flex-1 p-4">
                                <div className="flex items-start">
                                    <div className="ml-3 w-0 flex-1">
                                        <p className="text-sm font-medium text-gray-900">Cookies</p>
                                        <p className="mt-1 text-sm text-gray-500">We use cookies on this web page to help improve our products. By using this web page you consent to our usage of cookies, <a className="text-orange-400" href="https://www.vault56.com/download/vault56_cookie_policy.pdf" target="_blank">see cookie policy (PDF)</a>. If you do not consent to our use of cookies, close this web page.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex border-l border-gray-200">
                                <button
                                    type="button"
                                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    onClick={() => {
                                        setShow(false)
                                        localStorage.setItem('allowCookies', 'true');
                                    }}
                                >
                                    I ACCEPT
                                </button>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}
