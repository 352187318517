import V56Features from "../containers/v56features";

export default function V56home() {
    return (
        <>

            {/* Top left text */}
            <div className="grid sm:grid-cols-6 gap-4 pt-10" id="home">

                <div className="border-0 border-dotted sm:col-start-2 sm:col-end-4">
                    <div className="text-left max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <h2 className="inline text-3xl font-extrabold tracking-tight text-gray-900 sm:block sm:text-4xl">
                            Honest, easy to use storage
                        </h2>
                        <p className="inline text-3xl font-extrabold tracking-tight text-[#e6581c] sm:block sm:text-4xl">
                            vault56 Cloud Storage
                        </p>
                        <ul className="mt-6 space-y-4">
                            <li className="flex space-x-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 h-5 w-5 text-[#d21e48]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <span className="text-lg font-bold text-black">Data is stored in EU</span>
                                <img
                                    className="h-6 w-auto"
                                    src={require('../assets/img/euflag.png')}
                                    alt="vault56eu"
                                />

                            </li>
                            <li className="flex space-x-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 h-5 w-5 text-[#d21e48]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <span className="text-lg font-bold text-black">S3 compatible API</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 h-5 w-5 text-[#d21e48]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <span className="text-lg font-bold text-black">Ransomware protection</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 h-5 w-5 text-[#d21e48]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <span className="text-lg font-bold text-black">No fees for egress (selected plans)</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 h-5 w-5 text-[#d21e48]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <span className="text-lg font-bold text-black">Up to 80% cheaper than Amazon S3</span>
                            </li>
                        </ul>
                        <div className="grid sm:grid-cols-4 pt-5">
                            <a
                                href="https://secure.vault56.com/signup"
                                className='text-white bg-[#5f2881] hover:bg-[#5f2881] border-solid text-center border-2 border-[#e9e9e9] rounded-full rounded-md text-sm font-medium sm:col-span-1 p-2'
                                aria-current={undefined}
                            >
                                Start Free Trial
                            </a>
                            <div className="sm:col-span-1 pt-2">
                                <a
                                    href="#contact"
                                    className='text-black p-4 rounded-md text-sm font-medium hover:text-[#e6581c]'
                                    aria-current={undefined}
                                >
                                    Contact Sales
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-0 border-dotted sm:col-start-4 sm:col-end-6">
                    <div className="grid place-items-center max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <img
                            className="h-auto w-auto"
                            src={require('../assets/img/workplace_small.png')}
                            alt="vault56"
                        />
                    </div>
                </div>

            </div>

            <V56Features/>
        </>
    )
}
