import {legalPage_} from '../App';
import {useRecoilState} from "recoil";

export default function V56Footer() {
    const [isLegalPage, setIsLegalPage] = useRecoilState(legalPage_);
    return (
        <footer>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:justify-between lg:px-8">
                <img
                    className="h-8 w-auto float-left"
                    src={require('../assets/img/56_logo.png')}
                    alt="vault56"
                />
                <span className="text-gray-400">
                    <a href="#"
                    onClick={() => {
                        setIsLegalPage(true);
                    }}
                    >Legal</a>
                </span>
                <span className="text-right text-gray-400">
                    &copy; 2022 vault56. All rights reserved.
                </span>
            </div>
        </footer>
    )
}
