/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'

const faqs = [
    {
        question: "Business Account Agreement",
        answer:
            "<a href='https://www.vault56.com/download/vault56_baa.pdf'>Click here to view the business account agreement</a>",
    },
    {
        question: "Customer Agreement",
        answer:
            "<a href='https://www.vault56.com/download/vault56_ca.pdf'>Click here to view the customer agreement</a>",
    },
    {
        question: "Privacy Policy",
        answer:
            "<a href='https://www.vault56.com/download/vault56_privacy_policy.pdf'>Click here to view the privacy policy</a>",
    },
    {
        question: "Cookie Policy",
        answer:
            "<a href='https://www.vault56.com/download/vault56_cookie_policy.pdf'>Click here to view the cookie policy</a>",
    },
    // More questions...
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function V56Legal() {
    return (
        <div className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Legal</h2>
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                            <span className="font-medium text-gray-900">Business Account Agreement</span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500"><a href='https://www.vault56.com/download/vault56_baa.pdf'>Click here to view the business account agreement (PDF)</a></p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                            <span className="font-medium text-gray-900">Customer Agreement</span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500"><a href='https://www.vault56.com/download/vault56_ca.pdf'>Click here to view the customer agreement (PDF)</a></p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                            <span className="font-medium text-gray-900">Privacy Policy</span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500"><a href='https://www.vault56.com/download/vault56_privacy_policy.pdf'>Click here to view our privacy policy (PDF)</a></p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                            <span className="font-medium text-gray-900">Cookie Policy</span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500"><a href='https://www.vault56.com/download/vault56_cookie_policy.pdf'>Click here to view our cookie policy (PDF)</a></p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt className="text-lg">
                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                            <span className="font-medium text-gray-900">Service Level Agreement</span>
                                            <span className="ml-6 h-7 flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500"><a href='https://www.vault56.com/download/vault56_sla.pdf'>Click here to view our service level agreement (PDF)</a></p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </dl>
                </div>
            </div>
        </div>
    )
}
