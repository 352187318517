
export default function V56About() {
    return (
        <div className="py-16 bg-gray-50 overflow-hidden" id="about">
            <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                    <h2 className="text-base text-[#e6581c] font-semibold tracking-wide uppercase">cloud Storage Specialists</h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        What makes us different
                    </p>
                </div>
                <div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
                    <p className="text-lg text-gray-500">
                        We have reinvented cloud storage. Made it better, smarter, more secure, and easier to use.
                    </p>
                </div>
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
                    <div className="relative z-10">
                        <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
                            <p className="mb-5">
                                We looked at the existing alternatives out there, and we weren't impressed. There were hidden fees, it was to complicated to use, we wanted to make something better. Much better.
                            </p>
                            <p className="mb-5">
                                After years of hard work, in the spring of 2022, we released vault56 Cloud Storage. An affordable, secure, easy to use storage solution that just works.
                                Just works with your existing backup software and a multitude of third party solutions.
                                You can point your existing s3-compatible backup software to our cloud, and you are good to go. It's really that easy. Start feeling at ease and save big with minimal effort.
                            </p>

                            <p>
                                We store your data in EU.
                            </p>

                            <p className="mb-5">
                                Welcome to vault56 Cloud Storage.
                            </p>
                        </div>
                        <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
                            <div className="rounded-md shadow">
                                <a
                                    href="#contact"
                                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#5f2881] hover:bg-[#6f4488]"
                                >
                                    Contact sales
                                </a>
                            </div>
                        </div>
                        <img
                            className="h-auto w-auto pt-10"
                            src={require('../assets/img/woman.png')}
                            alt="woman"
                        />
                    </div>
                    <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
                        <svg
                            className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
                        </svg>
                        <div className="lg:py-24">
                            <div className="pb-16 bg-[#e6581c] lg:pb-0 lg:z-10 lg:relative rounded-t-3xl rounded-l-3xl">
                                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
                                    <div className="relative lg:-my-8">
                                        <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                                        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">

                                        </div>
                                    </div>
                                    <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                                            <blockquote>
                                                <div>
                                                    <svg
                                                        className="h-12 w-12 text-white opacity-25"
                                                        fill="currentColor"
                                                        viewBox="0 0 32 32"
                                                        aria-hidden="true"
                                                    >
                                                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                    </svg>
                                                    <p className="mt-6 text-2xl font-medium text-white">
                                                        We have taken the data storage pledge to a new level. Now everyone can afford secure, easy storage in the cloud.
                                                    </p>
                                                    <p className="mt-6 text-2xl font-medium text-white">
                                                        vault56 Cloud Storage is a true game changer.
                                                    </p>
                                                </div>
                                                <footer className="mt-6">
                                                    <p className="text-base font-medium text-white">Joel Svensson</p>
                                                    <p className="text-base font-medium text-indigo-100">CEO at vault56 AB</p>
                                                </footer>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
