import axios from 'axios'
import V56Header from "./components/header";
import {useEffect, useState} from "react";
import V56home from "./pages/v56home";
import V56About from "./pages/v56about";
import {V56Pricing} from "./pages/v56pricing";
import V56TablePricing from "./pages/v56tablePricing";
import V56Footer from "./components/footer";
import V56Contact from "./pages/v56contact";
import V56CookiePolicy from "./components/cookiePolicy";
import V56Legal from "./pages/v56legal";
import {atom, RecoilRoot, useRecoilState} from "recoil";

export const legalPage_ = atom({
    key: 'legalPage',
    default: false,
});

function App() {

    const [ip, setIP] = useState('');
    const [legalPage, setLegalPage] = useRecoilState(legalPage_);
    const allowCookies = (localStorage.getItem('allowCookies') === 'true');

    const getData = async () => {
        const res = await axios.get('https://api.vault56.com/v1/auth/myip', {withCredentials: false}).catch((e) => {
            setIP('');
        })
        setIP(res.data.IPv4)
    }

    useEffect( () => {
        //passing getData method to the lifecycle method
        //getData().then(r => null);

    }, [])

        if (!legalPage) {
            return(
                <>
                    <V56Header/>
                    <V56home/>
                    <V56TablePricing/>
                    <V56About/>
                    <V56Contact/>
                    <V56Footer/>
                    {allowCookies?'':<V56CookiePolicy/>}
                </>
            );

        } else {
            return(
                <>
                    <V56Header/>
                    <V56Legal/>
                </>
            );
        }
}

export default App;
